export const lightPalette = {
  mode: 'light',
  common: {
    black: '#000000',
    white: '#ffffff',
    transparent: 'rgba(255, 255, 255, 0)'
  },
  primary: {
    light: '#ffeff0',
    main: '#ff606d',
    dark: '#d74d58',
    contrastText: '#ffffff'
  },
  secondary: {
    main: '#ff808a',
    dark: '#ffcfd3',
    contrastText: '#ff606d'
  },
  text: {
    primary: '#000000',
    secondary: '#334156',
    disabled: '#99a0aa'
  },
  grey: {
    100: '#f8f9fa',
    200: '#ebecee',
    300: '#eeeeee',
    350: '#d6d9dd',
    400: '#858d9a',
    500: '#666666'
  },
  blue: {
    300: '#5c6778',
    700: '#707a89',
    800: '#1f2734'
  },
  rose: {
    300: '#ffafb6'
  },
  red: {
    50: '#FFEBEE',
    100: '#FFCDD2',
    200: '#EF9A9A',
    300: '#E57373',
    400: '#EF5350',
    500: '#F44336',
    600: '#E53935',
    700: '#E53935',
    800: '#C62828',
    900: '#B71C1C'
  },
  background: {
    default: '#fcfdff',
    paper: '#ffffff'
  },
  button: {
    text: {
      primary: '#ffffff',
      secondary: '#ff606d',
      tertiary: '#1F2734'
    }
  },
  chip: {
    turquoise: '#2ea5b1',
    blue: '#4064f4',
    grey: '#d6d9dd',
    support: '#2EA5B1'
  }
}
