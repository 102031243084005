import { Box, CircularProgress, IconButton } from '@material-ui/core'
import { useEffect, useState } from 'react'

import { BottomMobileNavigation, MenuItem, UserMenuDropdown } from './components'
import { useTopbarStyles } from './hooks'

import { Button } from '../Button'

import { Close } from '@material-ui/icons'
import { useLocation } from 'react-router-dom'
import LOGO from '../../../assets/stella-logo.svg'
import { loginUrl, signUpUrl } from '../../../util/api'
import { getIsUserClient, getIsUserProvider } from '../../../util/new/currentUser'
import { MenuItemLink } from './components/MenuItem'

export const Topbar = ({
  authInProgress,
  currentUser,
  isAuthenticated,
  onLogout,
  hideNav,
  onClose
}) => {
  const [activeMenuItem, setActiveMenuItem] = useState('')
  const { classes } = useTopbarStyles()

  const [isSignInLoading, setIsSignInLoading] = useState(false)
  const [isSignUpLoading, setIsSignUpLoading] = useState(false)

  const location = useLocation()

  const isProvider = getIsUserProvider(currentUser)
  const isClient = getIsUserClient(currentUser)

  useEffect(() => {
    const path = window.location.pathname

    if (path.endsWith('/s')) {
      setActiveMenuItem('/s')
    } else if (path.includes('/skin-analyser-scan')) {
      setActiveMenuItem('/skin-analyser-scan')
    } else if (path.includes('/practitioner-dashboard')) {
      setActiveMenuItem('/practitioner-dashboard')
    } else if (path.includes('/bookings')) {
      setActiveMenuItem('/bookings')
    } else if (path.includes('/profile-settings')) {
      setActiveMenuItem('/profile-settings')
    } else if (path.includes('/client-dashboard')) {
      setActiveMenuItem('/client-dashboard')
    }
  }, [])

  const renderMenuItems = () => {
    if (isAuthenticated && isProvider) {
      return (
        <Box className={classes.menuItemContainer}>
          <MenuItem
            name="PractitionerDashboardPage"
            className={classes.menuItem}
            isActive={activeMenuItem === '/practitioner-dashboard'}
          >
            Home
          </MenuItem>
          <MenuItem
            name="BookingsPage"
            className={classes.menuItem}
            isActive={activeMenuItem === '/bookings'}
          >
            Bookings
          </MenuItem>
          <MenuItem
            name="ProfileSettingsPage"
            className={classes.menuItem}
            isActive={activeMenuItem === '/profile-settings'}
          >
            Profile
          </MenuItem>
        </Box>
      )
    }

    if (isAuthenticated && !isProvider) {
      return (
        <Box className={classes.menuItemContainer}>
          <MenuItem
            name="ClientDashboardPage"
            className={classes.menuItem}
            isActive={activeMenuItem === '/client-dashboard'}
          >
            Home
          </MenuItem>
          <MenuItem
            name="SearchPage"
            className={classes.menuItem}
            isActive={activeMenuItem === '/s'}
          >
            Find Experts
          </MenuItem>
          <MenuItem
            name="SkinAnalyserScanPage"
            className={classes.menuItem}
            isActive={activeMenuItem === '/skin-analyser-scan'}
          >
            Take a Scan
          </MenuItem>
          <MenuItemLink
            href="https://www.seestella.com.au/collections/shop-all"
            target="_blank"
            className={classes.menuItem}
          >
            Shop Skin Health
          </MenuItemLink>
        </Box>
      )
    }

    return (
      <Box className={classes.menuItemContainer}>
        <MenuItem name="SearchPage" className={classes.menuItem} isActive={activeMenuItem === '/s'}>
          Find Experts
        </MenuItem>
        <MenuItem
          name="SkinAnalyserScanPage"
          className={classes.menuItem}
          isActive={activeMenuItem === '/skin-analyser-scan'}
        >
          Take a Scan
        </MenuItem>
        <MenuItemLink
          href="https://www.seestella.com.au/collections/shop-all"
          target="_blank"
          className={classes.menuItem}
        >
          Shop Skin Health
        </MenuItemLink>
      </Box>
    )
  }

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.innerContainer}>
          {hideNav ? (
            <>
              <img src={LOGO} alt="stella-logo" className={classes.logo} />
              {isAuthenticated ? (
                <Button
                  uiType="tertiary"
                  href={isProvider ? '/practitioner-dashboard' : '/client-dashboard'}
                >
                  Home
                </Button>
              ) : (
                <IconButton onClick={onClose}>
                  <Close />
                </IconButton>
              )}
            </>
          ) : (
            <>
              <a href="https://www.seestella.com.au/">
                <img src={LOGO} alt="stella-logo" className={classes.logo} />
              </a>

              {renderMenuItems()}
              {!authInProgress && isAuthenticated ? (
                <UserMenuDropdown currentUser={currentUser} onLogout={onLogout} />
              ) : (
                <Box className={classes.authButtonsContainer}>
                  <Button
                    uiType="tertiary"
                    onClick={() => setIsSignInLoading(true)}
                    href={loginUrl(location.pathname + location.search)}
                    isDisabled={isSignInLoading}
                    size="large"
                    className={classes.signInButton}
                  >
                    {isSignInLoading && (
                      <CircularProgress size={14} style={{ marginRight: '0.5rem' }} />
                    )}
                    Sign in
                  </Button>
                  <Button
                    uiType="primary"
                    onClick={() => setIsSignUpLoading(true)}
                    href={signUpUrl(location.pathname + location.search)}
                    isDisabled={isSignUpLoading}
                    size="large"
                    className={classes.signUpButton}
                  >
                    {isSignUpLoading && (
                      <CircularProgress size={14} style={{ marginRight: '0.5rem' }} />
                    )}
                    Sign up
                  </Button>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>

      {!hideNav && (
        <BottomMobileNavigation isAuthenticated={isAuthenticated} currentUser={currentUser} />
      )}
    </>
  )
}
