import {
  faBagShopping,
  faCalendar,
  faCamera,
  faHome,
  faMagnifyingGlass,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core'
import { useEffect, useState } from 'react'

import { useBottomNavigationStyles } from './hooks'

import { getIsUserProvider } from '../../../../../util/new/currentUser'
import NamedRedirect from '../../../../NamedRedirect/NamedRedirect'
import { Icon } from '../../../Icon'

export const BottomMobileNavigation = ({ isAuthenticated, currentUser }) => {
  const [value, setValue] = useState(null)
  const [redirect, setRedirect] = useState({ redirect: false, name: '' })
  const { joinedContainerClasses, joinedLabelClasses, joinedCenterButtonClasses } =
    useBottomNavigationStyles()

  const isProvider = getIsUserProvider(currentUser)

  useEffect(() => {
    const path = window.location.pathname
    if (isProvider) {
      // Logged in as provider
      if (path.includes('/practitioner-dashboard')) {
        setValue(0)
      } else if (path.includes('/bookings')) {
        setValue(1)
      } else if (path.includes('/profile-settings')) {
        setValue(2)
      }
    } else if (isAuthenticated && !isProvider) {
      // Logged in as client
      if (path.includes('/client-dashboard')) {
        setValue(0)
      } else if (path.endsWith('/s')) {
        setValue(1)
      } else if (path.includes('/skin-analyser-scan')) {
        setValue(2)
      } else if (path.includes('/profile-settings')) {
        setValue(4)
      }
    } else {
      // Logged out
      if (path.endsWith('/s')) {
        setValue(0)
      } else if (path.includes('/skin-analyser-scan')) {
        setValue(1)
      }
    }
  }, [isAuthenticated, isProvider])

  const renderNavigationActions = () => {
    if (isAuthenticated && isProvider) {
      return [
        <BottomNavigationAction
          onClick={() => setRedirect({ redirect: true, name: 'PractitionerDashboardPage' })}
          key="Home"
          label="Home"
          icon={<Icon icon={faHome} iconSize="lg" />}
          className={joinedLabelClasses}
        />,
        <BottomNavigationAction
          onClick={() => setRedirect({ redirect: true, name: 'BookingsPage' })}
          key="Bookings"
          label="Bookings"
          icon={<Icon icon={faCalendar} iconSize="lg" />}
          className={joinedCenterButtonClasses}
        />,
        <BottomNavigationAction
          onClick={() => setRedirect({ redirect: true, name: 'ProfileSettingsPage' })}
          key="Profile"
          label="Profile"
          icon={<Icon icon={faUser} iconSize="lg" />}
          className={joinedLabelClasses}
        />
      ]
    } else if (isAuthenticated && !isProvider) {
      return [
        <BottomNavigationAction
          onClick={() => setRedirect({ redirect: true, name: 'ClientDashboardPage' })}
          key="Home"
          label="Home"
          icon={<Icon icon={faHome} iconSize="lg" />}
          className={joinedLabelClasses}
        />,
        <BottomNavigationAction
          onClick={() => setRedirect({ redirect: true, name: 'SearchPage' })}
          key="Experts"
          label="Experts"
          icon={<Icon icon={faMagnifyingGlass} iconSize="lg" />}
          className={joinedLabelClasses}
        />,
        <BottomNavigationAction
          onClick={() => setRedirect({ redirect: true, name: 'SkinAnalyserScanPage' })}
          key="Scan"
          label="Scan"
          icon={<Icon icon={faCamera} iconSize="lg" />}
          className={joinedCenterButtonClasses}
        />,
        <BottomNavigationAction
          onClick={() => {
            window.open('https://www.seestella.com.au/collections/shop-all', '_blank')
          }}
          key="Shop"
          label="Shop"
          icon={<Icon icon={faBagShopping} iconSize="lg" />}
          className={joinedLabelClasses}
        />,
        <BottomNavigationAction
          onClick={() => setRedirect({ redirect: true, name: 'ProfileSettingsPage' })}
          key="Profile"
          label="Profile"
          icon={<Icon icon={faUser} iconSize="lg" />}
          className={joinedLabelClasses}
        />
      ]
    }

    // Default
    return [
      <BottomNavigationAction
        onClick={() => setRedirect({ redirect: true, name: 'SearchPage' })}
        key="Experts"
        label="Experts"
        icon={<Icon icon={faMagnifyingGlass} iconSize="lg" />}
        className={joinedLabelClasses}
      />,
      <BottomNavigationAction
        onClick={() => setRedirect({ redirect: true, name: 'SkinAnalyserScanPage' })}
        key="Scan"
        label="Scan"
        icon={<Icon icon={faCamera} iconSize="lg" />}
        className={joinedCenterButtonClasses}
      />,
      <BottomNavigationAction
        onClick={() => {
          window.open('https://www.seestella.com.au/collections/shop-all', '_blank')
        }}
        key="Shop"
        label="Shop"
        icon={<Icon icon={faBagShopping} iconSize="lg" />}
        className={joinedLabelClasses}
      />
    ]
  }

  return (
    <BottomNavigation value={value} className={joinedContainerClasses} showLabels>
      {renderNavigationActions()}
      {redirect.redirect && <NamedRedirect name={redirect.name} />}
    </BottomNavigation>
  )
}
