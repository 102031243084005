import { Link } from '@material-ui/core'
import NamedLink from '../../../../NamedLink/NamedLink'
import { useMenuItemStyles } from './hook'

export const MenuItem = ({ isActive, name, className, children }) => {
  const { joinedClasses } = useMenuItemStyles({ isActive, className })

  return (
    <NamedLink name={name} className={joinedClasses}>
      {children}
    </NamedLink>
  )
}

export const MenuItemLink = ({ isActive, href, target, className, children }) => {
  const { joinedClasses } = useMenuItemStyles({ isActive, className })

  return (
    <Link href={href} target={target} className={joinedClasses}>
      {children}
    </Link>
  )
}
