import { mapListingsToCommonStructure } from '../../../components/mui/utils/practitionerListing'
import { selectFavouriteListingIds } from '../../../ducks/user.duck'
import { listingIndex, listingRatingDescIndex } from '../../../lib/algolia'

// ================ ACTION TYPES ================ //

export const FETCH_LISTINGS_REQUEST = 'app/NewSearchPage/FETCH_LISTINGS_REQUEST'
export const FETCH_LISTINGS_SUCCESS = 'app/NewSearchPage/FETCH_LISTINGS_SUCCESS'
export const FETCH_LISTINGS_ERROR = 'app/NewSearchPage/FETCH_LISTINGS_ERROR'

export const FETCH_MAP_LISTINGS_REQUEST = 'app/NewSearchPage/FETCH_MAP_LISTINGS_REQUEST'
export const FETCH_MAP_LISTINGS_SUCCESS = 'app/NewSearchPage/FETCH_MAP_LISTINGS_SUCCESS'
export const FETCH_MAP_LISTINGS_ERROR = 'app/NewSearchPage/FETCH_MAP_LISTINGS_ERROR'

export const SET_CURRENT_PAGE = 'app/NewSearchPage/SET_CURRENT_PAGE'
export const SET_NB_PAGES = 'app/NewSearchPage/SET_NB_PAGES'
export const SET_SEARCH_RESULT = 'app/NewSearchPage/SET_SEARCH_RESULT'

export const SET_SORT_OPTION = 'app/NewSearchPage/SET_SORT_OPTION'
export const SET_FILTER_OPTIONS = 'app/NewSearchPage/SET_FILTER_OPTIONS'
export const SET_SPECIAL_OFFER_FILTER_OPTIONS = 'app/NewSearchPage/SET_SPECIAL_OFFER_FILTER_OPTIONS'
export const SET_EXPERIENCE_FILTER_OPTIONS = 'app/NewSearchPage/SET_EXPERIENCE_FILTER_OPTIONS'
export const SET_AREA_FILTER_OPTIONS = 'app/NewSearchPage/SET_AREA_FILTER_OPTIONS'
export const SET_SPECIALISATION_FILTER_OPTIONS =
  'app/NewSearchPage/SET_SPECIALISATION_FILTER_OPTIONS'
export const SET_MAX_START_PRICE_FILTER_OPTION =
  'app/NewSearchPage/SET_MAX_START_PRICE_FILTER_OPTION'
export const SET_ONLY_FAVOURITES_FILTER_OPTION =
  'app/NewSearchPage/SET_ONLY_FAVOURITES_FILTER_OPTION'
export const SET_CONSULTATION_FILTER_OPTION = 'app/newSearchPage/SET_CONSULTATION_FILTER_OPTION'
export const SET_LOCATION_FILTER_OPTION = 'app/NewSearchPage/SET_LOCATION_FILTER_OPTION'

export const SET_POTENTIALLY_RELEVANT_LISTINGS_NUMBER =
  'app/NewSearchPage/SET_POTENTIALLY_RELEVANT_LISTINGS_NUMBER'
export const SET_FAVOURITE_LISTING_IDS = 'app/NewSearchPage/SET_FAVOURITE_LISTING_ID'
export const SET_WEATHER_DATA = 'app/NewSearchPage/SET_WEATHER_DATA'

export const CLEAR_FILTER_OPTIONS = 'app/NewSearchPage/CLEAR_FILTER_OPTIONS'
export const CLEAR_SORTING_OPTIONS = 'app/NewSearchPage/CLEAR_SORTING_OPTIONS'

// ================ REDUCER ================ //

const initialState = {
  listings: [],
  currentPage: 0,
  nbPages: 0,
  searchResult: null,
  potentiallyRelevantListingsNumber: 0,
  sortOption: 'recommended', // or 'topRated', 'nearest'
  filterOptions: {
    specialOffer: [],
    experience: [],
    area: [],
    specialisation: [],
    maxStartPrice: 0,
    onlyFavourites: false,
    consultation: { id: '', name: '', category: '' },
    location: {
      name: '',
      lat: null,
      lon: null
    }
  },
  weatherData: null,
  fetchListingsInProgress: false,
  fetchListingsError: null
}

const practitionerSearchPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action

  switch (type) {
    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        fetchListingsInProgress: true,
        fetchListingsError: null
      }
    case FETCH_LISTINGS_SUCCESS:
      return { ...state, fetchListingsInProgress: false, listings: payload }
    case FETCH_LISTINGS_ERROR:
      return { ...state, fetchListingsInProgress: false, fetchListingsError: payload }
    case FETCH_MAP_LISTINGS_REQUEST:
      return { ...state, fetchListingsInProgress: true, fetchListingsError: null }
    case FETCH_MAP_LISTINGS_SUCCESS:
      return { ...state, fetchListingsInProgress: false, listings: payload }
    case FETCH_MAP_LISTINGS_ERROR:
      return { ...state, fetchListingsInProgress: false, fetchListingsError: payload }
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: payload }
    case SET_NB_PAGES:
      return { ...state, nbPages: payload }
    case SET_SEARCH_RESULT:
      return { ...state, searchResult: payload }
    case SET_SORT_OPTION:
      return { ...state, sortOption: payload }
    case SET_FILTER_OPTIONS:
      return { ...state, filterOptions: payload }
    case SET_SPECIAL_OFFER_FILTER_OPTIONS:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          specialOffer: payload
        }
      }
    case SET_EXPERIENCE_FILTER_OPTIONS:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          experience: payload
        }
      }
    case SET_AREA_FILTER_OPTIONS:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          area: payload
        }
      }
    case SET_SPECIALISATION_FILTER_OPTIONS:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          specialisation: payload
        }
      }
    case SET_MAX_START_PRICE_FILTER_OPTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          maxStartPrice: payload
        }
      }
    case SET_ONLY_FAVOURITES_FILTER_OPTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          onlyFavourites: payload
        }
      }
    case SET_CONSULTATION_FILTER_OPTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          consultation: payload,
          maxStartPrice: 0
        }
      }
    case SET_LOCATION_FILTER_OPTION:
      return {
        ...state,
        filterOptions: {
          ...state.filterOptions,
          location: payload
        }
      }
    case SET_POTENTIALLY_RELEVANT_LISTINGS_NUMBER:
      return {
        ...state,
        potentiallyRelevantListingsNumber: payload
      }
    case SET_WEATHER_DATA:
      return {
        ...state,
        weatherData: payload
      }
    case CLEAR_FILTER_OPTIONS:
      return {
        ...state,
        filterOptions: initialState.filterOptions
      }
    case CLEAR_SORTING_OPTIONS:
      return {
        ...state,
        sortOption: initialState.sortOption
      }
    default:
      return state
  }
}

export default practitionerSearchPageReducer

// ================ ACTION CREATORS ================ //

export const fetchListingsRequest = () => ({
  type: FETCH_LISTINGS_REQUEST
})

export const fetchListingsSuccess = (listings) => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: listings
})

export const fetchListingsError = (fetchError) => ({
  type: FETCH_LISTINGS_ERROR,
  payload: fetchError
})

export const fetchMapListingsRequest = () => ({
  type: FETCH_MAP_LISTINGS_REQUEST
})

export const fetchMapListingsSuccess = (listings) => ({
  type: FETCH_MAP_LISTINGS_SUCCESS,
  payload: listings
})

export const fetchMapListingsError = (fetchError) => ({
  type: FETCH_MAP_LISTINGS_ERROR,
  payload: fetchError
})

export const setCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  payload: page
})

export const setNbPages = (nbPages) => ({
  type: SET_NB_PAGES,
  payload: nbPages
})

export const setSearchResult = (searchResult) => ({
  type: SET_SEARCH_RESULT,
  payload: searchResult
})

export const setSortOption = (sortOption) => ({
  type: SET_SORT_OPTION,
  payload: sortOption
})

export const setFilterOptions = (filterOptions) => ({
  type: SET_FILTER_OPTIONS,
  payload: filterOptions
})

export const setSpecialOfferFilterOptions = (specialOffer) => ({
  type: SET_SPECIAL_OFFER_FILTER_OPTIONS,
  payload: specialOffer
})

export const setExperienceFilterOptions = (experience) => ({
  type: SET_EXPERIENCE_FILTER_OPTIONS,
  payload: experience
})

export const setAreaFilterOptions = (area) => ({
  type: SET_AREA_FILTER_OPTIONS,
  payload: area
})

export const setSpecialisationFilterOptions = (specialisation) => ({
  type: SET_SPECIALISATION_FILTER_OPTIONS,
  payload: specialisation
})

export const setMaxStartPriceFilterOption = (maxStartPrice) => ({
  type: SET_MAX_START_PRICE_FILTER_OPTION,
  payload: maxStartPrice
})

export const setOnlyFavouritesFilterOption = (onlyFavourites) => ({
  type: SET_ONLY_FAVOURITES_FILTER_OPTION,
  payload: onlyFavourites
})

export const setConsultationFilterOption = (consultation) => ({
  type: SET_CONSULTATION_FILTER_OPTION,
  payload: consultation
})

export const setLocationFilterOption = (location) => ({
  type: SET_LOCATION_FILTER_OPTION,
  payload: location
})

export const setPotentiallyRelevantListingsNumber = (count) => ({
  type: SET_POTENTIALLY_RELEVANT_LISTINGS_NUMBER,
  payload: count
})

export const setWeatherData = (weatherData) => ({
  type: SET_WEATHER_DATA,
  payload: weatherData
})

export const clearFilterOptions = () => ({
  type: CLEAR_FILTER_OPTIONS
})

export const clearSortingOptions = () => ({
  type: CLEAR_SORTING_OPTIONS
})

// ================ SELECTORS ================ //

// Root selector to get the practitioner search page state
const selectPractitionerSearchPage = (state) => state.NewSearchPage

// Selectors for listings
export const selectListings = (state) => selectPractitionerSearchPage(state).listings
export const selectPotentiallyRelevantListingsNumber = (state) =>
  selectPractitionerSearchPage(state).potentiallyRelevantListingsNumber

// Selectors for pagination
export const selectCurrentPage = (state) => selectPractitionerSearchPage(state).currentPage
export const selectNbPages = (state) => selectPractitionerSearchPage(state).nbPages
export const selectSearchResult = (state) => selectPractitionerSearchPage(state).searchResult

// Selectors for sort options
export const selectSortOption = (state) => selectPractitionerSearchPage(state).sortOption

// Selectors for filter options
export const selectFilterOptions = (state) => selectPractitionerSearchPage(state).filterOptions
export const selectSpecialOfferFilterOptions = (state) => selectFilterOptions(state).specialOffer
export const selectExperienceFilterOptions = (state) => selectFilterOptions(state).experience
export const selectAreaFilterOptions = (state) => selectFilterOptions(state).area
export const selectSpecialisationFilterOptions = (state) =>
  selectFilterOptions(state).specialisation
export const selectMaxStartPriceFilterOption = (state) => selectFilterOptions(state).maxStartPrice
export const selectOnlyFavouritesFilterOption = (state) => selectFilterOptions(state).onlyFavourites
export const selectConsultationFilterOption = (state) => selectFilterOptions(state).consultation
export const selectLocationFilterOption = (state) => selectFilterOptions(state).location

// Selectors for search parameters
export const selectSearchParams = (state) => selectPractitionerSearchPage(state).searchParams

// Selectors for weather data
export const selectWeatherData = (state) => selectPractitionerSearchPage(state).weatherData

// Selectors for fetching listings status
export const selectFetchListingsInProgress = (state) =>
  selectPractitionerSearchPage(state).fetchListingsInProgress
export const selectFetchListingsError = (state) =>
  selectPractitionerSearchPage(state).fetchListingsError

// ================ THUNKS ================ //

export const fetchPractitionerListingsOnMap =
  (boundingBoxCoordinates) => async (dispatch, getState) => {
    // dispatch(fetchMapListingsRequest())

    const state = getState()
    const filterOptions = selectFilterOptions(state)
    const favouriteListings = selectFavouriteListingIds(state)

    const facets = mapFilterOptionsToFacetsFormat(filterOptions)

    try {
      const listings = await listingIndex.search('', {
        facetFilters: facets,
        insideBoundingBox: [boundingBoxCoordinates]
      })
      dispatch(setSearchResult(listings))

      const mappedListings = mapListingsToCommonStructure(listings.hits)

      if (filterOptions.onlyFavourites) {
        const filteredListings = mappedListings.filter((listing) => {
          return favouriteListings.includes(listing.id)
        })

        dispatch(fetchMapListingsSuccess(filteredListings))
      } else {
        dispatch(fetchMapListingsSuccess(mappedListings))
      }
    } catch (error) {
      console.error('Error fetching practitioner listings:', error.message)
      dispatch(fetchMapListingsError(error))
    }
  }

export const fetchPractitionerListings =
  (page, forceIpLocation = false) =>
  async (dispatch, getState) => {
    dispatch(fetchListingsRequest())

    const state = getState()
    const sortOption = selectSortOption(state)
    const filterOptions = selectFilterOptions(state)
    const favouriteListings = selectFavouriteListingIds(state)

    const hasLocation = filterOptions.location.lat && filterOptions.location.lon
    let userLocation = filterOptions.location
    if (!hasLocation || forceIpLocation) {
      try {
        const ipAddressData = await getIpAddressCoordinates()
        const weatherData = await fetchWeatherData(ipAddressData.latitude, ipAddressData.longitude)
        const ipLocation = {
          name: `${ipAddressData.city}, ${ipAddressData.region}`,
          city: ipAddressData.city,
          lat: ipAddressData.latitude,
          lon: ipAddressData.longitude
        }
        userLocation = ipLocation
        dispatch(setLocationFilterOption(ipLocation))
        dispatch(setWeatherData(weatherData))
      } catch (err) {
        console.error(err)
        const fallbackLocation = {
          name: 'Melbourne, Victoria',
          lat: -37.8136,
          lon: 144.9631
        }
        userLocation = fallbackLocation
        dispatch(setLocationFilterOption(fallbackLocation))
      }
    }

    const maxStartPrice = filterOptions.maxStartPrice * 100
    const consultationId = filterOptions.consultation.id

    const facets = mapFilterOptionsToFacetsFormat(filterOptions)
    const numericFilters =
      consultationId && maxStartPrice > 0
        ? [`attributes.publicData.startingPrice.${consultationId} <= ${maxStartPrice}`]
        : undefined

    try {
      let listings
      const aroundLocation = `${userLocation.lat}, ${userLocation.lon}`
      if (sortOption === 'topRated') {
        listings = await listingRatingDescIndex.search('', {
          page: page || 0,
          facetFilters: facets,
          aroundLatLng: aroundLocation,
          aroundPrecision: sortOption === 'nearest' ? undefined : 20000,
          aroundRadius: 'all',
          numericFilters
        })
      } else {
        listings = await listingIndex.search('', {
          page: page || 0,
          facetFilters: facets,
          aroundLatLng: aroundLocation,
          aroundPrecision: sortOption === 'nearest' ? undefined : 20000,
          aroundRadius: 'all',
          numericFilters
        })
      }

      dispatch(setSearchResult(listings))

      const mappedListings = mapListingsToCommonStructure(listings.hits)

      if (filterOptions.onlyFavourites) {
        const filteredListings = mappedListings.filter((listing) => {
          return favouriteListings.includes(listing.id)
        })

        dispatch(fetchListingsSuccess(filteredListings))

        // Favorites are not paginated
        dispatch(setCurrentPage(0))
        dispatch(setNbPages(1))
      } else {
        dispatch(fetchListingsSuccess(mappedListings))
        dispatch(setCurrentPage(listings.page))
        dispatch(setNbPages(listings.nbPages))
      }
    } catch (error) {
      console.error('Error fetching practitioner listings:', error)
      dispatch(fetchListingsError(error))
    }
  }

export const fetchNumberOfPotentiallyRelevantListings = () => async (dispatch, getState, sdk) => {
  try {
    const state = getState()
    const sortOption = selectSortOption(state)
    const filterOptions = selectFilterOptions(state)

    const maxStartPrice = filterOptions.maxStartPrice * 100
    const consultationId = filterOptions.consultation.id

    const facets = mapFilterOptionsToFacetsFormat(filterOptions)
    const numericFilters =
      consultationId && maxStartPrice > 0
        ? [`attributes.publicData.startingPrice.${consultationId} <= ${maxStartPrice}`]
        : undefined
    const aroundLocation = `${filterOptions.location.lat}, ${filterOptions.location.lon}`

    let listings

    if (sortOption === 'topRated') {
      listings = await listingRatingDescIndex.search('', {
        facetFilters: facets,
        aroundLatLng: aroundLocation,
        aroundPrecision: sortOption === 'nearest' ? undefined : 20000,
        aroundRadius: 'all',
        numericFilters
      })
    } else {
      listings = await listingIndex.search('', {
        facetFilters: facets,
        aroundLatLng: aroundLocation,
        aroundPrecision: sortOption === 'nearest' ? undefined : 20000,
        aroundRadius: 'all',
        hitsPerPage: filterOptions.onlyFavourites ? 1000 : undefined,
        numericFilters
      })
    }

    dispatch(setSearchResult(listings))

    const mappedListings = mapListingsToCommonStructure(listings.hits)

    if (filterOptions.onlyFavourites) {
      const user = await sdk.currentUser.show()

      const favouriteListings =
        user.data.data.attributes.profile.privateData.favouriteListings || []

      const relevantListingsCount = mappedListings.filter((listing) =>
        favouriteListings.includes(listing.id)
      ).length

      dispatch(setPotentiallyRelevantListingsNumber(relevantListingsCount))
      // Favorites are not paginated
      dispatch(setCurrentPage(0))
      dispatch(setNbPages(1))
    } else {
      dispatch(setPotentiallyRelevantListingsNumber(listings.nbHits))
      dispatch(setCurrentPage(listings.page))
      dispatch(setNbPages(listings.nbPages))
    }
  } catch (err) {
    console.error('Error fetching potentially relevant listings:', err)
    dispatch(fetchListingsError(err))
  }
}

export const fetchWeatherData = async (lat, lon) => {
  try {
    const response = await fetch(
      `https://api.openweathermap.org/data/3.0/onecall?lat=${lat}&lon=${lon}&appid=15fcc2bfca007f560e81277562261d9a`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json'
        }
      }
    )

    if (!response.ok) {
      throw new Error(`Weather API Error: ${response.statusText}`)
    }

    const data = await response.json()
    return data
  } catch (err) {
    console.error('Error fetching weather data:', err)
  }
}

export const getIpAddressCoordinates = async () => {
  try {
    const response = await fetch('https://ipwhois.pro?key=wGdN4zPpZ1oPuYdj', {
      method: 'GET',
      headers: {
        Accept: 'application/json, text/plain, */*'
      }
    })

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`)
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error('Error fetching IP address coordinates:', error)
    throw error
  }
}

const mapFilterOptionsToFacetsFormat = (filterOptions) => {
  const { specialOffer, experience, area, specialisation, consultation } = filterOptions

  let facets = []

  if (consultation.id?.length > 0) {
    facets.push(`attributes.publicData.consultations:${consultation.id}`)
  }

  if (specialOffer.includes('online_consultation')) {
    facets.push('attributes.publicData.onlineConsultFlag:true')
  }

  if (specialOffer.includes('two_by_fifty')) {
    facets.push('attributes.publicData.special_offers_enum:two_by_fifty')
  }

  if (experience.length > 0) {
    facets.push([
      ...getExperienceRange(experience).map(
        (entry) => `attributes.publicData.years_of_experience:${entry}`
      )
    ])
  }

  facets.push(...specialisation.map((entry) => `attributes.publicData.specializations:${entry}`))
  facets.push(...area.map((entry) => `attributes.publicData.areas:${entry}`))

  return facets
}

const getExperienceRange = (experience) => {
  let numbers = []

  experience.forEach((experience) => {
    let parts = experience.split(',').filter((part) => part !== '')
    parts.forEach((part) => numbers.push(parseInt(part, 10)))
  })

  let min = Math.min(...numbers)
  let max = Math.max(...numbers)

  let range = []
  for (let i = min; i <= max; i++) {
    range.push(i)
  }

  return range
}
