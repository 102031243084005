// ================ ACTION TYPES ================ //

import { fetchCurrentUser } from '../../../ducks/user.duck'
import { startSkinAnalyserScan } from '../../../util/api'

export const START_SCAN_REQUEST = 'app/SkinAnalyser/START_SCAN_REQUEST'
export const START_SCAN_SUCCESS = 'app/SkinAnalyser/START_SCAN_SUCCESS'
export const START_SCAN_ERROR = 'app/SkinAnalyser/START_SCAN_ERROR'

export const LOAD_PAGE_REQUEST = 'app/SkinAnalyserScanPage/LOAD_PAGE_REQUEST'
export const LOAD_PAGE_SUCCESS = 'app/SkinAnalyserScanPage/LOAD_PAGE_SUCCESS'
export const LOAD_PAGE_ERROR = 'app/SkinAnalyserScanPage/LOAD_PAGE_ERROR'

export const CLEAR_SKIN_ANALYSER = 'app/SkinAnalyser/CLEAR_SKIN_ANALYSER'

// ================ REDUCER ================ //

const initialState = {
  data: null,
  fetchStartScanInProgress: false,
  fetchStartScanError: null,
  fetchInProgress: false,
  fetchError: null
}

const skinAnalyserReducer = (state = initialState, action = {}) => {
  const { type, payload } = action

  switch (type) {
    case START_SCAN_REQUEST:
      return {
        ...state,
        fetchStartScanInProgress: true,
        fetchStartScanError: null
      }
    case START_SCAN_SUCCESS:
      return {
        ...state,
        fetchStartScanInProgress: false,
        data: payload
      }
    case START_SCAN_ERROR:
      return {
        ...state,
        fetchStartScanInProgress: false,
        fetchStartScanError: payload
      }
    case LOAD_PAGE_REQUEST:
      return {
        ...state,
        fetchInProgress: true,
        fetchError: null
      }
    case LOAD_PAGE_SUCCESS:
      return {
        ...state,
        fetchInProgress: false,
        pageData: payload
      }
    case LOAD_PAGE_ERROR:
      return {
        ...state,
        fetchInProgress: false,
        fetchError: payload
      }
    case CLEAR_SKIN_ANALYSER:
      return initialState
    default:
      return state
  }
}

export default skinAnalyserReducer

// ================ ACTION CREATORS ================ //

export const startScanRequest = () => ({
  type: START_SCAN_REQUEST
})

export const startScanSuccess = (data) => ({
  type: START_SCAN_SUCCESS,
  payload: data
})

export const startScanError = (error) => ({
  type: START_SCAN_ERROR,
  payload: error
})

export const clearSkinAnalyser = () => ({
  type: CLEAR_SKIN_ANALYSER
})

export const loadPageRequest = () => ({
  type: LOAD_PAGE_REQUEST
})

export const loadPageSuccess = (data) => ({
  type: LOAD_PAGE_SUCCESS,
  payload: data
})

export const loadPageError = (error) => ({
  type: LOAD_PAGE_ERROR,
  payload: error
})

// ================ SELECTORS ================ //

export const selectSkinAnalyser = (state) => state.SkinAnalyserScanPage

export const selectData = (state) => selectSkinAnalyser(state).data
export const selectFetchInProgress = (state) => selectSkinAnalyser(state).fetchInProgress
export const selectFetchError = (state) => selectSkinAnalyser(state).fetchError
export const selectFetchStartScanInProgress = (state) =>
  selectSkinAnalyser(state).fetchStartScanInProgress
export const selectFetchStartScanError = (state) => selectSkinAnalyser(state).fetchStartScanError

// ================ THUNKS ================ //

export const startSkinAnalyserScanThunk = (params) => async (dispatch, getState, sdk) => {
  dispatch(startScanRequest())

  try {
    const result = await startSkinAnalyserScan(params)
    if (result.response) {
      dispatch(startScanSuccess(result.response))
    } else {
      throw new Error('Error starting skin analyser scan')
    }
  } catch (error) {
    console.error('Error starting skin analyser scan:', error)
    dispatch(startScanError(error))
  }
}

export const loadData = () => async (dispatch, getState, sdk) => {
  dispatch(loadPageRequest())

  return dispatch(fetchCurrentUser())
    .then((response) => {
      dispatch(loadPageSuccess())
      return response
    })
    .catch((error) => {
      console.error('Error loading page:', error)
      dispatch(loadPageError(error))
    })
}
