import { initializeApp } from 'firebase/app'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging'
import { addFcmToken } from '../api'
import { renderInfoToast } from './toast'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
let messaging = null

// Ensure Firebase Messaging only initializes in the browser
if (typeof window !== 'undefined') {
  isSupported()
    .then((supported) => {
      if (supported) {
        messaging = getMessaging(app)
      } else {
        console.warn('Firebase Messaging is not supported on this browser.')
      }
    })
    .catch(console.error)
}

const getFcmToken = async () => {
  try {
    if (!messaging) {
      // iOS app does not support Firebase Messaging
      // Instead, stores the push token in session storage
      handleiOSNotificationPermissions()
      return
    }

    // Request permission for notifications
    const permission = await Notification.requestPermission()

    if (permission === 'granted') {
      console.log('Notification permission granted.')

      // Get the FCM token
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY
      })

      // Start handling foreground messages
      handleForegroundMessage()

      // Save FCM token
      await addFcmToken({ token })
    } else if (permission === 'denied') {
      console.log('Notification permission denied.')
    } else if (permission === 'default') {
      console.log('Notification permission not yet known.')
    } else {
      console.log('Notification permission unknown.')
    }
  } catch (error) {
    console.error('Error setting up notifications:', error)
  }
}

const handleForegroundMessage = () => {
  try {
    if (messaging && Notification.permission === 'granted') {
      onMessage(messaging, (payload) => {
        renderInfoToast(`${payload?.notification?.body || 'You have a new notification'}`)
      })
    }
  } catch (error) {
    console.error('Error setting up notifications:', error)
  }
}

const handleiOSNotificationPermissions = () => {
  // Check if the push token is in session storage
  // Used by iOS app to send the push token to the backend
  const pushToken = sessionStorage.getItem('push-token')
  if (pushToken) {
    addFcmToken({ token: pushToken })
  }
}

// Connect to Firestore emulator if needed
if (process.env.REACT_APP_MARKETPLACE_ROOT_URL.includes('localhost')) {
  console.log('Connecting to Firestore emulator')
  connectFirestoreEmulator(db, '127.0.0.1', 8080)
}

const HAUT_AI_SCAN_PROGRESS_COLLECTION = 'HautAiScanProgress'

export {
  db,
  getFcmToken,
  handleForegroundMessage,
  handleiOSNotificationPermissions,
  HAUT_AI_SCAN_PROGRESS_COLLECTION,
  messaging
}
