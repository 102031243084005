import { fetchCurrentUser } from '../../../ducks/user.duck'

// ================ ACTION TYPES ================ //
export const LOAD_PAGE_REQUEST = 'app/LaunchPage/LOAD_PAGE_REQUEST'
export const LOAD_PAGE_SUCCESS = 'app/LaunchPage/LOAD_PAGE_SUCCESS'
export const LOAD_PAGE_ERROR = 'app/LaunchPage/LOAD_PAGE_ERROR'

// ================ REDUCER ================ //
const initialState = {
  pageData: null,
  fetchInProgress: false,
  fetchError: null
}

const launchPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_PAGE_REQUEST:
      return {
        ...state,
        fetchInProgress: true,
        fetchError: null
      }
    case LOAD_PAGE_SUCCESS:
      return {
        ...state,
        fetchInProgress: false,
        pageData: payload
      }
    case LOAD_PAGE_ERROR:
      return {
        ...state,
        fetchInProgress: false,
        fetchError: payload
      }
    default:
      return state
  }
}

export default launchPageReducer

// ================ ACTION CREATORS ================ //
export const loadPageRequest = () => ({
  type: LOAD_PAGE_REQUEST
})

export const loadPageSuccess = (data) => ({
  type: LOAD_PAGE_SUCCESS,
  payload: data
})

export const loadPageError = (error) => ({
  type: LOAD_PAGE_ERROR,
  payload: error
})

// ================ SELECTORS ================ //
export const selectLaunchPage = (state) => state.LaunchPage
export const selectPageData = (state) => selectLaunchPage(state).pageData
export const selectFetchInProgress = (state) => selectLaunchPage(state).fetchInProgress
export const selectFetchError = (state) => selectLaunchPage(state).fetchError

// ================ THUNKS ================ //
export const loadData = () => async (dispatch, getState, sdk) => {
  dispatch(loadPageRequest())

  return dispatch(fetchCurrentUser())
    .then((response) => {
      dispatch(loadPageSuccess())
      return response
    })
    .catch((error) => {
      console.error('Error loading page:', error)
      dispatch(loadPageError(error))
    })
}
